<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <h6 class="secondary--text subtitle-2">
          N° do Orçamento: # {{ order.budget_id }}
        </h6>
        <h6 class="secondary--text caption">Criado em: {{ createdOrder }}</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        v-if="showOrderCompanyData"
      >
        <SectionTitle title="Dados da empresa" />

        <v-list-item>
          <v-list-item-content class="secondary--text">
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Empresa:</span>
              {{ order.budget.company.company_name }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Telefone:</span>
              {{ order.budget.company.phone_number }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Email:</span>
              {{ order.budget.company.email }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">CNPJ:</span>
              {{ order.budget.company.cnpj }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">CEP:</span>
              {{ order.budget.company.cep }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Endereço:</span>
              {{ order.budget.company.address }}
              <span v-if="order.budget.company.number">{{
                ' - ' + order.budget.company.number
              }}</span>
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Cidade:</span>
              {{ order.budget.company.city }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <SectionTitle title="Dados do cliente">
          <template slot="action-buttons">
            <v-btn
              v-if="showEditOrders"
              @click="seeClient(order.budget.client.id)"
              class="mr-1"
              icon
            >
              <v-icon color="primary">fas fa-eye</v-icon>
            </v-btn>
          </template>
        </SectionTitle>

        <v-list-item>
          <v-list-item-content class="secondary--text">
            <v-list-item-title
              v-if="order.budget.client.person_type == 'PJ'"
              class="body-2"
            >
              <span class="subtitle-2">Razão Social:</span>
              {{ order.budget.client.company_name }}
            </v-list-item-title>
            <v-list-item-title
              v-if="order.budget.client.person_type == 'PJ'"
              class="body-2"
            >
              <span class="subtitle-2">Nome Fantasia:</span>
              {{ order.budget.client.trading_name }}
            </v-list-item-title>
            <v-list-item-title
              v-if="order.budget.client.person_type === 'PF'"
              class="body-2"
            >
              <span class="subtitle-2">Nome:</span>
              {{ order.budget.client.name }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Código:</span>
              {{ order.budget.client.id }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">IE:</span>
              <!-- retornanr o campo Inscrição Estadual do cliente -->
              {{ order.budget.client.state_registration }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Contato:</span>
              {{ order.budget.client.client_contact[0].name }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Telefone:</span>
              {{ order.budget.client.client_contact[0].phone_number }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Email:</span>
              {{ order.budget.client.client_contact[0].email }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2"> CPF/CNPJ: </span>
              {{ clientDocument }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">CEP:</span>
              {{ order.budget.client.client_address[0].cep }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Endereço:</span>
              {{ order.budget.client.client_address[0].address }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Bairro:</span>
              {{ order.budget.client.client_address[0].district }}
            </v-list-item-title>
            <v-list-item-title class="body-2">
              <span class="subtitle-2">Cidade:</span>
              {{ order.budget.client.client_address[0].city }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <SectionTitle title="Produtos">
          <template slot="action-buttons">
            <v-btn
              class="mr-2"
              x-large
              color="primary"
              icon
              @click="() => (hoverEffect = !hoverEffect)"
            >
              <v-icon size="20">{{
                hoverEffect
                  ? 'fas fa-compress-arrows-alt'
                  : 'fas fa-expand-arrows-alt'
              }}</v-icon>
            </v-btn>
          </template>
        </SectionTitle>

        <v-row class="mb-3">
          <v-col
            cols="12"
            lg="3"
          >
            <v-text-field
              :disabled="!showEditOrders"
              v-model="order.print_name"
              label="Nome para gravação"
              rounded
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <v-select
              :disabled="!showEditOrders"
              v-model="order.print_tag"
              label="Etiqueta"
              rounded
              outlined
              dense
              hide-details
              :items="tags"
              item-value="id"
              item-text="label"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
            v-if="order.print_tag == 3"
          >
            <v-text-field
              :disabled="!showEditOrders"
              v-model="order.other_tag"
              label="Outra Etiqueta"
              rounded
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(item, idx) in headers"
                  :key="idx"
                  :class="'text-' + item.align"
                  :width="item.width"
                >
                  {{ item.title }}
                </th>
              </tr>
            </thead>
            <tbody id="products-table-body">
              <tr
                v-for="(product, idx) in order_products"
                :key="idx"
                class="secondary--text"
                style="position: relative"
              >
                <input
                  :ref="`uploader-${idx}`"
                  type="file"
                  class="d-none"
                  @change="onFileChange"
                />
                <td v-show="altInput != idx">
                  <v-menu
                    v-if="product.alt_description"
                    open-on-hover
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!showEditOrders"
                        @click="infoAlt(idx)"
                        v-on="on"
                        v-bind="attrs"
                        color="error"
                        icon
                      >
                        <v-icon>fas fa-info</v-icon>
                      </v-btn>
                    </template>
                    <v-card width="400">
                      <v-card-text>
                        <h4>Motivo da alteração</h4>
                        <span>{{ product.alt_description }}</span>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </td>
                <td
                  v-show="altInput != idx"
                  class="text-center"
                >
                  <v-avatar
                    :size="hoverEffect ? 150 : 30"
                    rounded
                    class="elevation-1 my-2"
                    style="transition: all 0.2s"
                  >
                    <v-img
                      v-if="product.image"
                      :src="$store.state.imagePath + product.image"
                    />
                    <v-icon
                      v-else
                      small
                      color="primary"
                    >
                      fas fa-box
                    </v-icon>
                  </v-avatar>
                </td>
                <td v-show="altInput != idx">{{ product.reference }}</td>
                <td v-show="altInput != idx">{{ product.name }}</td>
                <td
                  v-show="altInput != idx"
                  class="text-center"
                >
                  {{ product.print ? product.print : '-' }}
                </td>
                <td
                  v-show="altInput != idx"
                  class="text-center"
                >
                  <v-chip
                    v-if="order.order_status >= 4"
                    class="white--text"
                    :color="getColor(product.order_product_status)"
                    :outlined="product.order_product_status > 400"
                    :style="
                      product.order_product_status > 400
                        ? 'border-style: dashed'
                        : ''
                    "
                    small
                  >
                    {{ checkStatus(product.order_product_status) }}
                  </v-chip>
                  <span v-else>-</span>
                </td>
                <td
                  v-show="altInput != idx"
                  class="text-center"
                >
                  <div v-if="product.color">
                    <v-avatar
                      size="20"
                      color="primary"
                    >
                      <v-img
                        v-if="product.color_img"
                        :src="$store.state.imagePath + product.color_img"
                      />
                      <v-icon
                        v-else
                        color="white"
                        >fas fa-pallete</v-icon
                      >
                    </v-avatar>
                    <span>
                      {{ product.color }}
                    </span>
                  </div>
                  <span v-else>-</span>
                </td>
                <td
                  v-show="altInput != idx"
                  class="text-center"
                >
                  <v-btn
                    v-if="product.art_src"
                    @click="seeArt(product)"
                    rounded
                    dense
                    color="primary"
                    icon
                    small
                  >
                    <v-icon small>fas fa-eye</v-icon>
                  </v-btn>
                  <input
                    @change="(e) => onFileChange(e, product.id, idx)"
                    type="file"
                    class="input-file d-none"
                    :id="`upload-${idx}`"
                  />
                  <label :for="`upload-${idx}`">
                    <v-btn
                      :disabled="!showEditOrders"
                      rounded
                      dense
                      color="indigo"
                      icon
                      small
                      tag="a"
                    >
                      <v-icon small>fas fa-file-upload</v-icon>
                    </v-btn>
                  </label>
                </td>
                <td
                  class="text-right"
                  v-show="altInput != idx"
                >
                  <span style="position: relative">
                    {{ product.default_qtty }}

                    <v-menu
                      width="200"
                      :value="openedQttyMenu === idx"
                      offset-y
                      :close-on-click="false"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ attrs }">
                        <v-icon
                          v-if="order.order_status < 4"
                          v-bind="attrs"
                          @click="openedQttyMenu = idx"
                          color="primary"
                          class="edit-icon"
                          :ripple="false"
                          size="15"
                        >
                          fas fa-edit
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-text class="d-flex align-center">
                          <v-text-field
                            v-model.number="product.alt_qtty"
                            autofocus
                            label="Qtd."
                            placeholder="Digite uma quantidade"
                            v-mask="['########']"
                            solo
                            flat
                            dense
                            hide-details
                          />
                          <v-btn
                            rounded
                            color="primary"
                            @click="toggleAlt(idx, product)"
                            small
                            :ripple="false"
                            >salvar</v-btn
                          >
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </span>

                  <div
                    v-if="product.alt_qtty"
                    class="error--text"
                  >
                    {{ product.alt_qtty }}
                  </div>
                </td>

                <td
                  v-show="altInput != idx"
                  class="text-right"
                >
                  <span v-if="!showOrderPriceData">***</span>

                  <div
                    v-else-if="product.alt_unitary"
                    class="error--text"
                  >
                    {{ 'R$ ' + product.alt_unitary }}
                  </div>

                  <span
                    v-else
                    style="position: relative"
                  >
                    {{
                      product.default_unitary.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    }}

                    <v-menu
                      :value="openedCostMenu === idx"
                      offset-y
                      :close-on-click="false"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ attrs }">
                        <v-icon
                          v-if="order.order_status < 4"
                          v-bind="attrs"
                          @click="openedCostMenu = idx"
                          color="primary"
                          class="edit-icon"
                          :ripple="false"
                          size="15"
                        >
                          fas fa-edit
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-text class="d-flex align-center">
                          <v-text-field
                            v-model="product.alt_unitary"
                            autofocus
                            label="Valor UN"
                            placeholder="R$ 0,00"
                            v-mask="['#,##', '##,##', '###,##']"
                            solo
                            flat
                            dense
                            hide-details
                          />
                          <v-btn
                            rounded
                            color="primary"
                            @click="toggleAlt(idx, product)"
                            small
                            :ripple="false"
                            >Salvar</v-btn
                          >
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </span>
                </td>
                <td
                  v-if="altInput == null"
                  class="text-right"
                >
                  <div v-if="!showOrderPriceData">***</div>
                  <div v-else-if="product.alt_unitary">
                    {{ getSubtotal(product.alt_unitary, product.alt_qtty) }}
                  </div>
                  <div
                    v-else
                    class="error--text"
                  >
                    {{
                      getSubtotal(product.default_unitary, product.default_qtty)
                    }}
                  </div>
                </td>
                <td
                  v-show="altInput == idx"
                  colspan="10"
                >
                  <v-text-field
                    v-model="product.alt_description"
                    style="border: 1px solid var(--v-error-base)"
                    label="Motivo da alteração"
                    placeholder="Por que os valores estão sendo alterados?"
                    :disabled="order.order_status >= 4"
                    autofocus
                    solo
                    flat
                    hide-details
                    dense
                  />
                </td>
                <td
                  v-show="altInput == idx"
                  style="width: 250px"
                  class="text-right"
                >
                  <v-btn
                    v-if="!isEditingAltDescritpion"
                    text
                    rounded
                    small
                    @click="cancelAltInput(product)"
                    color="primary"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    v-if="order.order_status < 4"
                    rounded
                    :disabled="!product.alt_description"
                    small
                    @click="setAltValues"
                    color="primary"
                    >Salvar</v-btn
                  >
                  <v-btn
                    v-else
                    @click="altInput = null"
                    color="primary"
                    outlined
                    rounded
                    small
                    >Cancelar</v-btn
                  >
                </td>
              </tr>
              <tr
                v-if="cookies != 4"
                class="grey lighten-3"
              >
                <td
                  class="text-right secondary--text subtitle-1"
                  colspan="11"
                  v-show="showOrderPriceData"
                >
                  <span>Total das mercadorias: </span>
                  <span class="font-weight-bold">{{ getProductsTotal }}</span>
                  <div
                    v-if="getProductsAltTotal != null"
                    class="error--text"
                  >
                    {{ getProductsAltTotal }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        cols="12"
        v-if="productionType"
      >
        <v-textarea
          disabled
          v-model="order.observation_intern"
          label="Observações internas"
          placeholder="Observações internas"
          rounded
          outlined
          dense
          auto-grow
        />
      </v-col>
    </v-row>
    <v-dialog
      class="white"
      width="600"
      v-model="artDialog.opened"
    >
      <v-card
        flat
        dark
      >
        <v-img
          :src="$store.state.imagePath + artDialog.src"
          :lazy-src="$store.state.imagePath + artDialog.src"
          max-width="100%"
          max-height="600"
          contain
        >
          <v-btn
            class="white"
            @click="artDialog.opened = false"
            absolute
            top
            right
            icon
            color="error"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
  },

  data: () => ({
    headers: [
      { title: '', width: 30 },
      { title: 'Imagem', align: 'center', width: 50 },
      { title: 'Código', align: 'left', width: 50 },
      { title: 'Nome', align: 'left' },
      { title: 'Gravação', align: 'center', width: 300 },
      { title: 'Status', align: 'center', width: 170 },
      { title: 'Cor', align: 'center', width: 170 },
      { title: 'Arte', align: 'center' },
      { title: 'Quantidade', align: 'right', width: 150 },
      { title: 'Preço UN', align: 'right', width: 150 },
      { title: 'Subtotal', align: 'right', width: 150 },
    ],
    cookies: 0,
    hoverEffect: false,
    openedQttyMenu: null,
    openedCostMenu: null,
    altInput: null,
    altQttyAux: null,
    altUnitaryAux: null,
    isEditingAltDescritpion: false,
    tags: [
      { label: 'Não colar', id: '1' },
      { label: 'Magazine Brindes', id: '2' },
      { label: 'Outro', id: '3' },
    ],
    uploadId: null,
    artDialog: {
      opened: false,
      src: '',
    },
  }),
  computed: {
    ...mapGetters('comercial', [
      'showEditOrders',
      'showOrderCompanyData',
      'showOrderPriceData',
    ]),
    productionType() {
      return this.$cookies.get('type') === 'production'
    },
    getProductsTotal() {
      var total = 0
      this.order.order_products.map((el) => {
        let subtotal = el.default_unitary * el.default_qtty
        total = total + subtotal
      })

      return total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
    getProductsAltTotal() {
      var total = 0
      var subtotal = 0
      var altCount = 0
      var unitary = 0

      this.order.order_products.map((el) => {
        if (el.alt_unitary && el.alt_qtty) {
          altCount++
        }

        if (el.alt_unitary && el.alt_unitary.includes(',')) {
          unitary = parseFloat(el.alt_unitary.replace(',', '.'))
          subtotal = unitary * el.alt_qtty
        } else {
          subtotal = el.default_unitary * el.default_qtty
        }

        total = total + subtotal
      })

      if (altCount == 0) return null

      return total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
    clientDocument() {
      console.log(this.order.budget)
      if (this.order.budget.client.cpf_cnpj != null) {
        return this.order.budget.client.cpf_cnpj
      }

      return ' Não informado'
    },
    createdOrder() {
      return this.order.approved_registration_date
        .slice(0, 10)
        .split('-')
        .reverse()
        .join('/')
    },
    order_products() {
      this.setOrderProducts()
      return this.order.order_products
    },
  },
  methods: {
    getSubtotal(cost, qtty) {
      if (!cost || !qtty) return ''

      if (typeof cost == 'string' && cost.includes(',')) {
        cost = parseFloat(cost.replace(',', '.'))
      }

      return parseFloat((qtty * cost).toFixed(2)).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
    setOrderProducts() {
      this.order.budget.products.map((budget_product) => {
        this.order.order_products.map((order_product) => {
          if (order_product.budget_product_id === budget_product.id) {
            if (budget_product.is_temporary) {
              order_product.image = budget_product.product.temp_image
                ? budget_product.product.temp_image
                : null
              order_product.reference = budget_product.product.id
              order_product.name = budget_product.product.product_name
            } else {
              order_product.image = budget_product.product_images_selected_color
                ? budget_product.product_images_selected_color.src
                : null
              order_product.color = budget_product.color.name
              order_product.color_img = budget_product.color.image_color
              order_product.reference = budget_product.product.product_reference
              order_product.name = budget_product.product.product_name
              order_product.print =
                budget_product.vendor_finance_id == 1
                  ? budget_product.print.name
                  : budget_product.product_vendor_selected_cost_table.title
            }
          }
        })
      })
    },
    toggleAlt(idx, product) {
      if (product.alt_qtty && product.alt_unitary && !product.alt_description) {
        this.altInput = idx
        this.isEditingAltDescritpion = false
      }
      this.openedCostMenu = null
      this.openedQttyMenu = null

      if (!product.alt_qtty || !product.alt_unitary) {
        product.alt_description = null
      }
    },
    cancelAltInput(product) {
      product.alt_qtty = null
      product.alt_unitary = null
      product.alt_description = null
      this.altInput = null
    },
    setAltValues() {
      this.altInput = null
    },
    infoAlt(idx) {
      this.altInput = idx
      this.isEditingAltDescritpion = true
    },
    onFileChange(e, id, idx) {
      let formData = new FormData()
      formData.append('id', id)
      formData.append('image', e.target.files[0])

      this.$api
        .post('orders/products/upload_art', formData)
        .then((res) => {
          this.order_products[idx].art_src = res.data.data
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Arte adicionada com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .catch((err) => {
          console.error(err)
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Falha ao adicionar a arte',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .finally(() => {
          this.artDialog.opened = false
        })
    },
    seeClient(id) {
      const routeData = this.$router.resolve({ path: '/people/clients/' + id })
      window.open(routeData.href, '_blank')
    },
    seeArt(item) {
      this.uploadId = item.id
      this.artDialog.opened = true
      this.artDialog.src = item.art_src
    },
    checkStatus(status) {
      switch (status) {
        case 41:
          return 'Aguardando compra'
        case 411:
          return 'Parcialmente comprado'
        case 42:
          return 'Aguardando gravação'
        case 421:
          return 'Parcialmente gravado'
        case 43:
          return 'Aguardando conferência'
        case 431:
          return 'Parcialmente conferido'
        case 44:
          return 'Aguardando produção'
        case 441:
          return 'Parcialmente produzido'
        case 45:
          return 'Aguardando faturamento'
        case 451:
          return 'Parcialmente faturado'
        case 46:
          return 'Aguardando finalização'
        case 47:
          return 'Finalizado'
      }
      return status
    },
    getColor(status) {
      switch (status) {
        case 41:
          return 'blue-grey'
        case 411:
          return 'blue-grey'
        case 42:
          return 'indigo'
        case 421:
          return 'indigo'
        case 43:
          return 'cyan'
        case 431:
          return 'cyan'
        case 44:
          return 'deep-purple'
        case 441:
          return 'deep-purple'
        case 45:
          return 'orange lighten-2'
        case 451:
          return 'orange'
        case 46:
          return 'teal'
        case 47:
          return 'success'
      }
      return status
    },
  },
}
</script>

<style>
.input-file {
  appearance: none;
}
</style>
